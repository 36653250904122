import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD1ZsKf_x_PoBDR-mbUOfy3gnx8WEtA_0s",
  authDomain: "eve-down-under-68896.firebaseapp.com",
  databaseURL: "https://eve-down-under-68896-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "eve-down-under-68896",
  storageBucket: "eve-down-under-68896.appspot.com",
  messagingSenderId: "657264058555",
  appId: "1:657264058555:web:035c52c1b93b4d8af1499e",
  measurementId: "G-1D99KHWMW0"
};

// Initialize Firebase
export const firebase = initializeApp(firebaseConfig);
export const analytics = getAnalytics(firebase);