<template>
    <div>
        <v-app-bar clipped-left app clipped color="grey darken-4 white--text" dark>
            <v-app-bar-nav-icon class="hidden-md-and-up" @click="drawer = !drawer"></v-app-bar-nav-icon>          <v-spacer class="hidden-md-and-up"></v-spacer>

            <router-link to="/" class="toolbar-title">
                <v-toolbar-title class="headline text-uppercase">
                    <span>Eve Down Under</span>
                    <span class="font-weight-light">2023</span>
                </v-toolbar-title>
            </router-link>
            <v-spacer class="hidden-sm-and-down"></v-spacer>
            
            <AdminMenu v-if="isAuthed"/>
 
        </v-app-bar>
        <v-navigation-drawer clipped app v-model="drawer" disable-resize-watcher>
            <v-list>
                <div  v-for="(item, index) in mobileMenu" :key="index" >
                    <v-list-item  :to="item.link">
                        <v-list-item-content>
                            {{item.title}}
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                </div>
       
            </v-list>
        </v-navigation-drawer>
        
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AdminMenu from './AdminMenu.vue';
import { getAuth } from 'firebase/auth';

export default {
    name: "AppNavigation",
    components: {
        AdminMenu,
    },  
    data() {
        return {
            appTitle: "Eve Down Under 2020",
            drawer: false,
            mobileMenu: [
                {
                    title: "Home",
                    link: "/"
                }
            ]
        };
    },
    components: { AdminMenu },
    computed:{
        ...mapGetters('auth', [
          'isAuthed',
        ]),
    }
};
</script>

<style scoped>
.toolbar-title {
  color: inherit;
  text-decoration: inherit;
}
</style>