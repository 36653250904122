<template>


    <footer class="teal darken-2">
        <v-layout :row="$vuetify.breakpoint.smAndDown" style class="my-2" id="footer-layout">
          <v-flex xs12 >
            <div class="white--text ml-3">
              Made with <v-icon>favorite</v-icon> by the <span class="grey--text text--lighten-2">Eve Down Under</span> team.
            </div>
      
          </v-flex>
          <v-spacer />

          <template v-if="!isAuthed">
            <LoginPopover />
          </template>
          <template v-else>
            <v-layout justify-end :style="{ gap: '10px' }">
              <div class="white--text">{{ user.email }}</div>
              <v-btn x-small @click="handleLogout">Logout</v-btn>
            </v-layout>
          </template>
          
    
        </v-layout>
    </footer>
</template>

<script>
import { mapGetters } from 'vuex';
import LoginPopover from './LoginPopover.vue';
import {getAuth, signOut} from 'firebase/auth';
export default {
    components: { LoginPopover },   
    computed: {
        ...mapGetters('auth', [
          'isAuthed',
          'user'
        ]),
    },
    methods: {
      handleLogout()
      {
        const auth = getAuth();
        signOut(auth).then(() => {
          const meta = this.$route.meta;
          if(meta.requiresAuth){
            this.$router.push('/')
          }
        });
      }
    }
}
</script>

<style scoped>
@media (min-width: 800px) {
  #footer-layout {
    width:70%!important;
    margin:auto;
  }
}
#footer-layout {
  width: 100%;
}
#footer {
  overflow: auto;
}
footer {
  overflow: auto;
}

</style>
