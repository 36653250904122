<template>

    <v-dialog v-model="isOpen" width="500">
        <template v-slot:activator="{on, attrs}">
            <v-btn text v-bind="attrs" x-small v-on="on">Admin</v-btn>
        </template>
        <v-card>
            <v-card-title>Admin Login</v-card-title>

            <v-card-text>
                <v-text-field :disabled="authenticating" label="Username" v-model="email" />
                <v-text-field :disabled="authenticating" type="password" label="Password" v-model="password" />

                <template v-if="errorMessage != null">
                    {{errorMessage}}
                </template>
            </v-card-text>

            <v-card-actions>
                <v-spacer />
                <v-btn :loading="authenticating" color="teal" @click="handleAuthentication">Login</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>



</template>

<script>
import {getAuth, signInWithEmailAndPassword} from 'firebase/auth';
export default {

    data: () => ({
        authenticating: false,
        isOpen: false,
        email: "",
        password: "",
        errorMessage: null,
    }),
 
    methods: {
        reset()
        {
            this.errorMessage = null;
            this.email = "";
            this.password = "";

            this.isOpen = false;
        },
        handleAuthentication()
        {
            this.errorMessage = null;
            this.authenticating = true;
            const auth = getAuth();

            signInWithEmailAndPassword(auth, this.email, this.password)
            .then((credentials) => {
                this.reset();
            }).catch((error) => {  
                this.errorMessage = error.message;
            }).finally(() => {
                this.authenticating = false;
            })
        }
    }
}
</script>