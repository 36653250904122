

export default {
    namespaced: true,
    state() {
        return {
            user: null,
        }   
    },

    mutations: {
        setUser(state, user)
        {
            state.user = user;
        },

    },

    actions: {
        setUser({dispatch, commit}, user)
        {
            return new Promise((resolve, reject) => {
                commit('setUser', user);
                resolve();
            })
        }
    },

    getters: {
        isAuthed(state){
            return state.user != null;
        },

        user(state)
        {   
            return state.user;
        }
    }
}