import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"teal darken-2"},[_c(VLayout,{staticClass:"my-2",attrs:{"row":_vm.$vuetify.breakpoint.smAndDown,"id":"footer-layout"}},[_c(VFlex,{attrs:{"xs12":""}},[_c('div',{staticClass:"white--text ml-3"},[_vm._v(" Made with "),_c(VIcon,[_vm._v("favorite")]),_vm._v(" by the "),_c('span',{staticClass:"grey--text text--lighten-2"},[_vm._v("Eve Down Under")]),_vm._v(" team. ")],1)]),_c(VSpacer),(!_vm.isAuthed)?[_c('LoginPopover')]:[_c(VLayout,{style:({ gap: '10px' }),attrs:{"justify-end":""}},[_c('div',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.user.email))]),_c(VBtn,{attrs:{"x-small":""},on:{"click":_vm.handleLogout}},[_vm._v("Logout")])],1)]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }