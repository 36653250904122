<template>
  <div>
    <section>
      <v-parallax :src="require('../assets/parralax1.jpg')" height="380" alt="Rocks in Space">
        <v-layout column align-center justify-center>
          <img src="../assets/guristaroo.png" alt="Guristaroo" height="200">
          <h2 class="white--text text--lighten-2 mb-2">Eve Down Under 2023</h2>
          <h4 class="teal--text  headline mb-3 text-xs-center">Sydney</h4>
        </v-layout>
      </v-parallax>

      <div class="content my-2">
        <v-row>
          <v-col class="d-flex justify-center" :style="{gap: '10px'}">
            <v-card height="315">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/TkL1TEFnzpQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </v-card>
            <v-card  height="315">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/OI68XGhqE6k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </v-card>
            <v-card v-if="false">
              <v-card-title>
                This will be EVE’s first virtual event, which is being supported and endorsed by CCP.
              </v-card-title>
              <v-card-text>
                <ul>
                  <li>Loads of e-bling prizes; PLEX, ship skins and limited edition skins!</li>
                  <li>Custom PVP Arena for the tournament</li>
                  <li>CCP Commentating the tournament and coming along for the roams</li>
                  <li>CCP Hosts active throughout the event</li>
                </ul>
              </v-card-text>
            </v-card>
          </v-col>

        </v-row>
        <v-row>
          <v-col >
            <v-card class="w-full h-full pt-2 d-flex flex-column ">
              <div class="text-center">
                <v-icon x-large class="brown--text">map</v-icon>
              </div>
              <v-card-title primary-title class="text-center">
                <v-responsive class="headline text-center">
                 Location
                </v-responsive>
              </v-card-title>
              <v-card-text class="">
                So far we know that the event will be somewhere in Sydney! 
                
              </v-card-text>
      
              <v-card-actions v-if="false" class="mt-auto">
               
                  <v-btn color="teal darken-3 white--text" href='https://www.twitch.tv/ccp' target="_blank">EDU Live Stream</v-btn>
      
              </v-card-actions>
          </v-card>
          </v-col>
          <v-col >
            <v-card class="w-full pt-2 h-full">
              <div class="text-center">
                <v-icon x-large class="brown--text">flag</v-icon>
              </div>
              <v-card-title primary-title>
                <v-responsive class="headline text-center">
                  PvP Tournament
                </v-responsive>
              </v-card-title>
              <v-card-text>
                This year will have a PVP Tournament! 
                Make sure you read up on the rules! 
                <p class="mt-4">Signups will open soon!.</p>
              </v-card-text>
              <v-card-actions >

                  
               
                  <v-btn color="teal darken-3 white--text" href='https://docs.google.com/document/d/1gzrQyxTncaf-VFXCC8XjXwC7gyP0d4EPeM5AP733kVY' target="_blank">View The Rules</v-btn>
                  <v-btn v-if="false" color="teal darken-3 white--text" href='https://forms.gle/73aEZpq2hQLTLmmG8' target="_blank">Sign Up</v-btn>
      
              </v-card-actions>
          </v-card>
          </v-col>
          <v-col >
            <v-card class="w-full pt-2 h-full d-flex flex-column">
              <div class="text-center">
                <v-icon x-large class="brown--text">schedule</v-icon>
              </div>
              <v-card-title primary-title>
                <v-responsive class="headline text-center">
                  EDU Schedule
                </v-responsive>
              </v-card-title>
              <v-card-text class="">
                Checkout what your can expect on the day!
              </v-card-text>
              <v-spacer></v-spacer>
              <v-card-actions class="">
               
                  <v-btn color="teal darken-3 white--text" to="schedule">View the Schedule</v-btn>
      
              </v-card-actions>
          </v-card>
          </v-col>

      </v-row>
      </div>

      <div class="content my-2">
        <v-row>
          <v-col>
            <v-btn href="https://www.eventbrite.com/e/eve-down-under-2023-sydney-tickets-681346454587?aff=oddtdtcreator" block color="teal" class="white--text">Get your tickets here!</v-btn>
          </v-col>
        </v-row>
      </div>
      
    </section>

    <section>
      <div class="content my-5">
        <v-card elevation="0">
          <v-card-title>
            Contact Us
          </v-card-title>
          <v-card-text>
            There are a few ways you can stay up to date with the latest Eve Down Under 2020 information. Feel free to reach out to us at any of the following locations:
            <v-list>
                  <a href="https://discord.gg/dZ6ayvP" target="_blank" rel="noreferrer nofollow">
                    <v-list-item>
                      <v-list-item-avatar><img src="../assets/Discord-Logo-Color.png" alt="Discord" height="100"></v-list-item-avatar>
                      <v-list-item-content>Eve Down Under Discord Server</v-list-item-content>
                    </v-list-item>
                  </a>
                  <a href="https://twitter.com/Evedownunder" target="_blank" rel="noreferrer nofollow">
                    <v-list-item>
                      <v-list-item-avatar><img src="../assets/Twitter_Logo_Blue.png" alt="Twitter" height="100"></v-list-item-avatar>
                      <v-list-item-content>@Evedownunder on Twitter</v-list-item-content>
                    </v-list-item>
                  </a>
                  <a href="https://www.facebook.com/evedownunder/" target="_blank" rel="noreferrer nofollow">
                    <v-list-item>
                      <v-list-item-avatar><img src="../assets/facebook.png" alt="Facebook" height="100"></v-list-item-avatar>
                      <v-list-item-content>Eve Down Under on Facebook</v-list-item-content>
                    </v-list-item>
                  </a>
                </v-list>
          </v-card-text>
        </v-card>
      </div>
    </section>
    <section>
      <v-parallax :src="require('../assets/parralax3.jpg')" height="380" alt="Upside-down Monitor flying through space">
        <v-layout column align-center justify-center>
        </v-layout>
      </v-parallax>
    </section>
  </div>
</template>

<script>

  export default {
    components: {
    }
  }
</script>

<style scoped>
.h-full{
  height: 100%
}
.w-full{
  width: 100%;
}
@media (min-width: 800px) {
  .content {
    width: 70%;
    margin: auto;
  }
}
#subtitle {
  font-weight: 200!important;
}
.card {
  background-color: #fafafa!important;
}

.flexcard {
  display: flex;
  flex-direction: column;
}

</style>
