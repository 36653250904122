import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import store from './store';
import {getAuth} from 'firebase/auth';
Vue.use(Router)



const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/schedule',
      name: 'schedule',
      component: () => import('./views/Schedule.vue')
    },
    {
      path: '/schedule/edit',
      name: 'schedule-edit',
      component: () => import('./views/ScheduleManager.vue'),
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: "/unauthorized",
      name: "unauthorized",
      component: () => import('./views/NotAuthorized.vue'),
    },
    {
      path: '*',
      name: 'not-found',
      component: () => import('./views/NotFound.vue'),
      
    }
    
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
 
  if(to.matched.some(record => record.meta.requiresAuth) && !router.app.$store.getters['auth/isAuthed']) {
    next("/unauthorized")
  }else {
    next();
  }
})

export default router;
