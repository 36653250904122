import { VBtn } from 'vuetify/lib/components/VBtn';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({on, attr}){return [_c(VBtn,_vm._g(_vm._b({attrs:{"small":"","color":"red"}},'v-btn',attr,false),on),[_vm._v("Admin")])]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c(VList,[_c('router-link',{attrs:{"to":"/schedule/edit"}},[_c(VListItem,[_c(VListItemTitle,[_vm._v(" Schedule Editor ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }