<template>
    <v-menu v-model="menu" offset-y>
        <template v-slot:activator="{on, attr}">
            <v-btn v-on="on" v-bind="attr" small color="red">Admin</v-btn>
        </template>

        <v-list>
            <router-link to="/schedule/edit">
                <v-list-item>
                    <v-list-item-title>
                        Schedule Editor
                    </v-list-item-title>
                </v-list-item>
            </router-link>
        </v-list>
    </v-menu>
</template>

<script>
    export default {

        data: () => ({
            menu: false
        })
    }
</script>