import Vue, { onMounted } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import * as VueGoogleMaps from "vue2-google-maps";
import vuetify from './plugins/vuetify';
import {firebase} from './plugins/firebase';
import {getAuth } from 'firebase/auth';

Vue.config.productionTip = false;

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyD4-LTVaDTh6kiwzaadlV9tiDti_XkkO9g",
    libraries: "places"
  }
});

const auth = getAuth();

let app;

auth.onAuthStateChanged(user => {

  store.dispatch("auth/setUser", user).then(() => {
    if(!app){
      app = new Vue({
        router,
        store,
        vuetify,
        render: h => h(App)
        
      }).$mount('#app')
    }
  })
})

