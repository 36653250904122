<template>
  <v-app :style="{background: $vuetify.theme.themes.light.background}">
    <AppNavigation/>

    <v-main>
        <router-view/>
    </v-main>
    
    <Footer/>
  </v-app>
</template>

<script>
import Footer from './components/Footer.vue'
import AppNavigation from './components/AppNavigation.vue'
export default {
  name: 'App',
  components: {
    Footer,
    AppNavigation
  },
  data () {
    return {
      menu: [
        {
          title: 'Schedule',
          link: 'schedule'
        }
      ]
    }
  }
}
</script>
<style scoped>
.toolbar-title {
  color: inherit;
  text-decoration: inherit;
}
</style>